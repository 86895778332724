<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">
        <template v-if="contract.Id">Edit</template>
        <template v-else>Add</template> Contract
      </h3>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="contract-id">Contract ID<span>*</span></label>
          <div class="form-field">
            <input
              class="form-control"
              id="contract-id"
              placeholder="Contract ID"
              type="text"
              v-model.trim="contract.ContractId"
              autofocus
            />
            <span
              v-if="submitted && v$.contract.ContractId.required.$invalid"
              class="error"
              >* required.</span
            >
            <span v-if="v$.contract.ContractId.maxLength.$invalid" class="error"
              >Contract ID should have at most
              {{ v$.contract.ContractId.maxLength.$params.max }}.</span
            >
            <span v-if="v$.contract.ContractId.numeric.$invalid" class="error"
              >Contract ID should have numeric characters.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="offer-id">Offer ID</label>
          <div class="form-field">
            <input
              class="form-control"
              id="offer-id"
              placeholder="Offer ID"
              type="text"
              v-model.trim="contract.OfferId"
            />
            <!-- <span v-if="submitted && v$.contract.OfferId.required.$invalid" class="error">* required.</span> -->
            <span v-if="v$.contract.OfferId.numeric.$invalid" class="error"
              >Offer ID should have numeric characters.</span
            >
            <span v-if="v$.contract.OfferId.maxLength.$invalid" class="error"
              >Offer ID should have at most
              {{ v$.contract.OfferId.maxLength.$params.max }}.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="company-name">Company Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="company-name"
              placeholder="Company Name"
              type="text"
              v-model.trim="contract.CompanyName"
            />
            <!-- <span v-if="submitted && v$.contract.CompanyName.required.$invalid" class="error">* required.</span> -->
            <span
              v-if="v$.contract.CompanyName.maxLength.$invalid"
              class="error"
              >Company Name should have at most
              {{ v$.contract.CompanyName.maxLength.$params.max }}.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="team-name">Team Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="team-name"
              placeholder="Team Name"
              type="text"
              v-model.trim="contract.TeamName"
            />
            <!-- <span v-if="submitted && v$.contract.TeamName.required.$invalid" class="error">* required.</span> -->
            <span v-if="v$.contract.TeamName.maxLength.$invalid" class="error"
              >Team Name should have at most
              {{ v$.contract.TeamName.maxLength.$params.max }}.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="agency-name">Agency Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="agency-name"
              placeholder="Agency Name"
              type="text"
              v-model.trim="contract.AgencyName"
            />
            <!-- <span v-if="submitted && v$.contract.AgencyName.required.$invalid" class="error">* required.</span> -->
            <span v-if="v$.contract.AgencyName.maxLength.$invalid" class="error"
              >Agency Name should have at most
              {{ v$.contract.AgencyName.maxLength.$params.max }}.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="title">Title<span>*</span></label>
          <div class="form-field">
            <input
              class="form-control"
              id="title"
              placeholder="Title"
              type="text"
              v-model.trim="contract.Title"
            />
            <span
              v-if="submitted && v$.contract.Title.required.$invalid"
              class="error"
              >* required.</span
            >
            <span v-if="v$.contract.Title.maxLength.$invalid" class="error"
              >Title should have at most
              {{ v$.contract.Title.maxLength.$params.max }}.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center radio-fields">
          <label class="form-label" for="status">Status</label>
          <div class="radio-options">
            <ul class="d-flex align-items-center inline-radio">
              <li>
                <input
                  type="radio"
                  id="active"
                  name="radio-group"
                  value="Active"
                  v-model.trim="contract.Status"
                  @change="contract.EndDate = ''"
                  :disabled="oldContractStatus === 'Ended'"
                />
                <label for="active">Active</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="ended"
                  name="radio-group"
                  value="Ended"
                  v-model.trim="contract.Status"
                  @change="contract.EndDate = ''"
                  :disabled="oldContractStatus === 'Ended'"
                />
                <label for="ended">Ended</label>
              </li>
              <li>
                <input
                  type="radio"
                  id="suspended"
                  name="radio-group"
                  value="Suspended"
                  v-model.trim="contract.Status"
                  :disabled="oldContractStatus === 'Ended'"
                />
                <label for="suspended">Suspended</label>
              </li>
            </ul>
            <!-- <span v-if="submitted && v$.contract.Status.required.$invalid" class="error">* required.</span> -->
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="start-date">Start Date</label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                id="start-date"
                :maxDate="$filters.dateFormat('', 'YYYY-MM-DD')"
                :enableTimePicker="false"
                autoApply
                placeholder="Start Date"
                v-model="contract.StartDate"
                :disabled="contract.Id ? true : false"
                @update:modelValue="contract.EndDate = ''"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
            </div>
            <span
              v-if="submitted && v$.contract.StartDate.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="end-date">End Date</label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                id="end-date"
                :disabled="!contract.StartDate || contract.Status === 'Active'"
                :minDate="new Date(contract.StartDate)"
                :maxDate="new Date()"
                :enableTimePicker="false"
                autoApply
                placeholder="End Date"
                v-model="contract.EndDate"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
            </div>
            <span
              v-if="submitted && v$.contract.EndDate.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>

      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Contract-type">Contract type</label>
          <div class="form-field">
            <select
              class="form-control select-box"
              v-model.trim="contract.ContractType"
              @change="
                (contract.FixedPriceAmountAgreed = ''),
                  (contract.HourlyRate = ''),
                  (contract.Milestones = []),
                  contract.Milestones.push({
                    Id: '',
                    Name: '',
                    DueDate: '',
                    Amount: '',
                  })
              "
              :disabled="contract.Id"
            >
              <option value="Hourly">Hourly</option>
              <option value="Fixed Price">Fixed</option>
            </select>
            <span
              v-if="submitted && v$.contract.ContractType.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
        <div
          class="col form-group d-flex align-items-center"
          v-if="contract.ContractType === 'Hourly'"
        >
          <label class="form-label" for="hourly-rate">Hourly Rate</label>
          <div class="form-field">
            <div class="hourly-rate-field">
              <span class="field-icon">$</span>
              <input
                class="form-control"
                placeholder="Hourly Rate"
                type="text"
                v-model.trim="contract.HourlyRate"
                onpaste="return false;"
                v-on:keypress="HourlyRate($event)"
              />
            </div>
            <span
              v-if="submitted && v$.contract.HourlyRate.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
        <div
          class="col form-group d-flex align-items-center"
          v-if="contract.ContractType === 'Fixed Price'"
        >
          <label class="form-label" for="fixed-rate">Fixed Amount</label>
          <div class="form-field">
            <div class="hourly-rate-field">
              <span class="field-icon">$</span>
              <input
                class="form-control"
                placeholder="Fixed Rate"
                type="text"
                onpaste="return false;"
                :value="contract.FixedPriceAmountAgreed"
                readonly
                disabled
              />
            </div>
            <span
              v-if="
                submitted &&
                v$.contract.FixedPriceAmountAgreed.required.$invalid
              "
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>

      <div
        class="form-field-group milestones"
        v-show="contract.ContractType === 'Fixed Price'"
      >
        <div
          v-for="(v, index) in v$.contract.$model.Milestones"
          :key="index"
          class="multi-companies step-moreLang"
          :id="`milestone-${index + 1}`"
        >
          <button
            v-if="index > 0"
            class="field-close"
            @click.prevent="
              contract.Milestones.splice(index, 1),
                updateFixedPriceAmountAgreed()
            "
          >
            <span class="lnr lnr-cross-circle"></span>
          </button>
          <h3 class="form-title">Milestones {{ index + 1 }}</h3>
          <div class="row grid-1">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label" :for="`name-${index}`">Name</label>
              <div class="form-field">
                <input
                  class="form-control"
                  type="text"
                  :id="`name-${index}`"
                  v-model.trim="v.Name"
                />
                <template
                  v-if="
                    v$.contract.Milestones.$each.$response.$data[index].Name
                      .$invalid
                  "
                >
                  <span
                    v-if="
                      submitted &&
                      !v$.contract.Milestones.$each.$response.$data[index].Name
                        .required
                    "
                    class="error"
                    >* required.</span
                  ><span
                    v-if="
                      !v$.contract.Milestones.$each.$response.$data[index].Name
                        .maxLength
                    "
                    class="error"
                    >Name should have at most 250 characters.</span
                  >
                </template>
              </div>
            </div>
          </div>
          <div class="row grid-2">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label">Due Date</label>
              <div class="form-field">
                <div class="date-field">
                  <datepicker
                    :id="`due-date-${index + 1}`"
                    v-model="v.DueDate"
                    :minDate="new Date()"
                    :enableTimePicker="false"
                    autoApply
                    placeholder="Due Date"
                    :format="format"
                  >
                    <template #input-icon>
                      <span class="lnr lnr-calendar-full"></span>
                    </template>
                  </datepicker>
                  <template
                    v-if="
                      submitted &&
                      v$.contract.Milestones.$each.$response.$data[index]
                        .DueDate.$invalid
                    "
                  >
                    <span
                      v-if="
                        !v$.contract.Milestones.$each.$response.$data[index]
                          .DueDate.required
                      "
                      class="error"
                      >* required.</span
                    >
                  </template>
                </div>
              </div>
            </div>
            <div class="col form-group d-flex align-items-center">
              <label class="form-label" for="fixed-rate">Amount</label>
              <div class="form-field">
                <div class="hourly-rate-field">
                  <span class="field-icon">$</span>
                  <input
                    class="form-control"
                    placeholder="Amount"
                    type="text"
                    onpaste="return false;"
                    v-model.trim="v.Amount"
                    v-on:keypress="HourlyRate($event)"
                    @input="updateFixedPriceAmountAgreed"
                  />
                </div>
                <span
                  v-if="
                    submitted &&
                    !v$.contract.Milestones.$each.$response.$data[index].Amount
                      .required
                  "
                  class="error"
                  >* required.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="add-action--btn">
          <span
            class="add-more-btn"
            @click.prevent="
              contract.Milestones.push({
                Id: '',
                Name: '',
                DueDate: '',
                Amount: '',
              })
            "
            >+ Add More</span
          >
        </div>
      </div>

      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center" v-if="false">
          <label class="form-label" for="weekly-salary">Weekly Salary</label>
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Weekly Salary"
              type="text"
              v-model.trim="contract.WeeklySalary"
              v-on:keypress="NumbersOnly($event)"
              onpaste="return false;"
            />
            <!-- <span v-if="submitted && v$.contract.WeeklySalary.required.$invalid" class="error">* required.</span> -->
            <span v-if="v$.contract.WeeklySalary.numeric.$invalid" class="error"
              >Weekly Salary should have numeric characters.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="upfront-payment"
            >Upwork Commission (%)</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Upwork Commission (%)"
              type="text"
              v-model.trim="contract.UpworkCommission"
              v-on:keypress="NumbersOnly($event)"
              onpaste="return false;"
            />
            <!-- <span v-if="submitted && v$.contract.UpworkCommission.required.$invalid" class="error">* required.</span> -->
            <span
              v-if="v$.contract.UpworkCommission.numeric.$invalid"
              class="error"
              >Upwork Commission should have numeric characters.</span
            >
            <span
              v-if="v$.contract.UpworkCommission.between.$invalid"
              class="error"
              >Upwork Commission between 0 - 100.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="weekly-limit">Weekly Limit</label>
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Weekly Limit"
              type="text"
              v-model.trim="contract.WeeklyLimit"
              v-on:keypress="NumbersOnly($event)"
              onpaste="return false;"
            />
            <!-- <span v-if="submitted && v$.contract.WeeklyLimit.required.$invalid" class="error">* required.</span> -->
            <span v-if="v$.contract.WeeklyLimit.numeric.$invalid" class="error"
              >Weekly Limit should have numeric characters.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Contact-person">Client name</label>
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Client name"
              type="text"
              v-model.trim="contract.ContactPerson"
            />
            <!-- <span v-if="submitted && v$.contract.ContactPerson.required.$invalid" class="error">* required.</span> -->
            <span
              v-if="v$.contract.ContactPerson.maxLength.$invalid"
              class="error"
              >Client name should have at most
              {{ v$.contract.ContactPerson.maxLength.$params.max }}.</span
            >
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="upfront-payment"
            >Upfront Payment (%)</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Upfront Payment (%)"
              type="text"
              v-model.trim="contract.UpfrontPayment"
              v-on:keypress="NumbersOnly($event)"
              onpaste="return false;"
            />
            <!-- <span v-if="submitted && v$.contract.UpfrontPayment.required.$invalid" class="error">* required.</span> -->
            <span
              v-if="v$.contract.UpfrontPayment.numeric.$invalid"
              class="error"
              >Upfront Payment should have numeric characters.</span
            >
            <span
              v-if="v$.contract.UpfrontPayment.between.$invalid"
              class="error"
              >Upfront Payment between 0 - 100.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-2" v-if="false">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Milestone-Status"
            >Milestone Status</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Milestone Status"
              type="text"
              v-model.trim="contract.MilestoneStatus"
            />
            <!-- <span v-if="submitted && v$.contract.MilestoneStatus.required.$invalid" class="error">* required.</span> -->
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Escrow-Refund-Status"
            >Escrow Refund Status</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Escrow Refund Status"
              type="text"
              v-model.trim="contract.EscrowRefundStatus"
            />
            <!-- <span v-if="submitted && v$.contract.EscrowRefundStatus.required.$invalid" class="error">* required.</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="form-controls">
      <div class="button-row d-flex justify-content-end">
        <button
          class="ml-auto secondary-btn"
          title="Cancel"
          type="button"
          @click.prevent="$emit('close')"
        >
          CANCEL
        </button>
        <button
          class="ml-3 js-btn-next primary-btn"
          type="submit"
          @click.prevent="submitHandler()"
        >
          <template v-if="contract.Id">Update</template>
          <template v-else>Create</template>
        </button>
      </div>
    </div>
  </form>
</template>
<script>

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import useVuelidate from "@vuelidate/core";
import {
  required,
  maxLength,
  numeric,
  requiredIf,
  between,
  helpers,
} from "@vuelidate/validators";
import common from "../mixins/common";
import contractService from "../services/contract.service";
import NumbersOnly from "../mixins/numbersOnly";

export default {
  setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${(day < 10)? '0'+day : day}/${ (month < 10)? '0'+month : month }/${year}`;
    };

    return {
      format,
      v$: useVuelidate(),
    };
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  mixins: [NumbersOnly, common],
  components: {
    Datepicker,
  },
  data: () => ({
    submitted: false,
    contract: {
      Id: 0,
      ContractId: "",
      OfferId: "",
      CompanyName: "",
      TeamName: "",
      AgencyName: "",
      Title: "",
      StartDate: new Date(),
      EndDate: "",
      Status: "Active",
      ContractType: "Hourly",
      HourlyRate: 0,
      FixedPriceAmountAgreed: 0,
      UpfrontPayment: 0,
      WeeklySalary: 0,
      UpworkCommission: 0,
      WeeklyLimit: 0,
      ContactPerson: "",
      MilestoneStatus: "",
      EscrowRefundStatus: "",
      UprosterProfileId: "",
      Milestones: [
        {
          Id: "",
          Name: "",
          DueDate: "",
          Amount: "",
        },
      ],
    },
    oldContractStatus: '',
  }),
  validations() {
    return {
      contract: {
        ContractId: {
          required,
          maxLength: maxLength(150),
          numeric,
        },
        OfferId: {
          //required,
          maxLength: maxLength(150),
          numeric,
        },
        CompanyName: {
          //required,
          maxLength: maxLength(150),
        },
        TeamName: {
          //required,
          maxLength: maxLength(150),
        },
        AgencyName: {
          //required,
          maxLength: maxLength(150),
        },
        Title: {
          required,
          maxLength: maxLength(150),
        },
        StartDate: {
          required,
        },
        EndDate: {
          required: requiredIf(function () {
            if (this.contract.Status === "Ended") {
              return true;
            }

            return false;
          }),
        },
        Status: {
          //required
        },
        ContractType: {
          required,
        },
        HourlyRate: {
          required: requiredIf(function () {
            if (this.contract.ContractType === "Hourly") {
              return true;
            }

            return false;
          }),
        },
        FixedPriceAmountAgreed: {
          required: requiredIf(function () {
            if (this.contract.ContractType === "Fixed Price") {
              return false;
            }

            return false;
          }),
        },
        UpfrontPayment: {
          //required,
          numeric,
          between: between(0, 100),
        },
        UpworkCommission: {
          //required,
          numeric,
          between: between(0, 100),
        },
        WeeklySalary: {
          //required,
          numeric,
        },
        WeeklyLimit: {
          //required,
          numeric,
        },
        ContactPerson: {
          //required,
          maxLength: maxLength(150),
        },
        MilestoneStatus: {
          //required,
        },
        EscrowRefundStatus: {
          //required
        },
        Milestones: {
          $each: helpers.forEach({
            Name: {
              required: requiredIf(function () {
                if (this.contract.ContractType === "Fixed Price") {
                  return true;
                }

                return false;
              }),
              maxLength: maxLength(250),
            },
            DueDate: {
              required: requiredIf(function () {
                return false;
              }),
            },
            Amount: {
              required: requiredIf(function () {
                if (this.contract.ContractType === "Fixed Price") {
                  return true;
                }

                return false;
              }),
            },
          }),
        },
      },
    };
  },
  created() {
    if (
      "contractData" in this.profile &&
      "Id" in this.profile.contractData &&
      this.profile.contractData.Id
    ) {
      this.contract = this.profile.contractData;
      this.oldContractStatus = this.contract.Status;
      if (!this.contract.Milestones || this.contract.Milestones.length === 0) {
        this.contract.Milestones = [
          {
            Id: "",
            Name: "",
            DueDate: "",
            Amount: "",
          },
        ];
      }
    }
  },
  methods: {
    async submitHandler() {
      let vm = this;

      vm.submitted = true;
      vm.v$.$touch();

      if (!vm.contract.Milestones) {
        vm.contract.Milestones = [
          {
            Id: "",
            Name: "",
            DueDate: "",
            Amount: "",
          },
        ];
      }

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      let formData = vm.contract;

      formData.HourlyRate = formData.HourlyRate ? formData.HourlyRate : 0;
      formData.FixedPriceAmountAgreed = formData.FixedPriceAmountAgreed
        ? formData.FixedPriceAmountAgreed
        : 0;
      formData.WeeklySalary = formData.WeeklySalary ? formData.WeeklySalary : 0;
      formData.WeeklyLimit = formData.WeeklyLimit ? formData.WeeklyLimit : 0;
      formData.UpworkCommission = formData.UpworkCommission
        ? formData.UpworkCommission
        : 0;
      formData.UpfrontPayment = formData.UpfrontPayment
        ? formData.UpfrontPayment
        : 0;
      formData.UprosterProfileId = vm.profile.Id;

      //Fixed Price
      if (formData.ContractType === "Hourly") {
        formData.Milestones = null;
      }

      if (vm.contract.Id) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await contractService.update(
            formData,
            vm.contract.Id
          );
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshContracts");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      } else {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await contractService.create(formData);
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshContracts");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    updateFixedPriceAmountAgreed() {
      const FixedPriceAmountAgreed = this.contract.Milestones.reduce(
        (a, b) => a + (parseFloat(b["Amount"]) || 0),
        0
      );
      this.contract.FixedPriceAmountAgreed =
        FixedPriceAmountAgreed % 1 === 0
          ? FixedPriceAmountAgreed
          : FixedPriceAmountAgreed.toFixed(2);
    },
  },
};
</script>
