export default {
  methods: {
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    HourlyRate(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46)) {
        evt.preventDefault();
      } else {
        if (evt.target.value > 99999.99) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    PhoneNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 43) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
}