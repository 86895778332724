import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class ProfileRosterService {

  async get(searchquery, profileType, skill) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}profile-listing`, {
        search: searchquery,
        profileType: profileType,
        skill: skill
      }, config);

    return response.data;
  }

  async create(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}create-profile`, data, config);
    return response.data;
  }

  async update(data, profileId) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}update-profile/${profileId}`, data, config);
    return response.data;
  }

  async destroy(id) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}delete-profile/${id}`, {}, config);
    return response.data;
  }

  async getEditProfileData(profileId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}edit-profile/${profileId}`, config);
    return response.data;
  }

  async getProfileData(profileId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}profile-detail/${profileId}`, config);
    return response.data;
  }

  async getProfileType() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}profile-type`, config);
    return response.data;
  }

  async getSkills() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}skills`, config);
    return response.data;
  }

  async getDropdownData() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}prefixes`, config);
    return response.data;
  }

  async getEditProfileDataReform(profileId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}edit-api-reform/${profileId}`, config);
    return response.data;
  }

  async import(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}import-upwork-profile`, data, config);

    return response.data;
  }
  // PORTFOLIO API'S 

  // contract-end-month-list
  async getContractEndMonthList(data) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}contract-end-month-list`, data, config);
    return response.data;
  }

  // getContractsBasedOnProfileId
  async getContractBasedOnProfileIdAndMonth(data) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}enddate-contract-details`, data, config);
    return response.data;
  }

  // Add/Remove Portfolio
  async AddRemovePortfolio(data) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}add-remove-portflio`, data, config);
    return response.data;
  }

}



export default new ProfileRosterService();