<template>
  <vue3-chart-js
    v-show="billingChart.data?.datasets?.length"
    ref="chartRef"
    v-bind="{ ...billingChart }"
    :id="`billing-chart`"
  />
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref, reactive } from "vue";
import dataLabels from "chartjs-plugin-datalabels";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const chartRef = ref(null);

    const billingChart = reactive({
      type: "bar",
      plugins: [dataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: { mode: null },
        plugins: {
          legend: false,
          title: false,
          tooltip: { enabled: false },
          datalabels: {
            color: '#fff',
            font: {
              weight: '400',
              size: '16px "Montserrat", sans-serif',
            },
            padding: 0,
            formatter: function(value) {
              let finalValue = value;
              if(finalValue % 1 === 0){
                finalValue = Math.round(value);
              }
              return `${finalValue}`;
            },
          },
        },
        elements: {
          bar: {
            backgroundColor: "#6fda45",
            borderColor: "#6fda45",
          },
        },
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            grid: {
              drawBorder: false,
              display: true,
            },
            min: 0,
            ticks: {
              stepSize: 50,
            },
            title: {
              display: true,
              text: 'Hours',
              font: {
                color: '#666',
                weight: '300',
                size: '13px "Montserrat", sans-serif',
              },
            }
          },
        },
      },
      data: {
        labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
        datasets: [
          {
            label: "",
            data: [],
            barThickness: 60,
            barPercentage: 60,
          },
        ],
      },
    });

    const getData = async () => {
      billingChart.data.labels = ["Week 1", "Week 2", "Week 3", "Week 4"];
      billingChart.data.datasets[0].data = props.datasets;
      chartRef.value.update(250)
    };
    return {
      billingChart,
      chartRef,
      getData,
    };
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
