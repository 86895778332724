import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class ContractService {

  async getContractsByProfileId(ProfileId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}contract/list/${ProfileId}`, config);

    return response.data;
  }

  async create(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'application/json';
    const response = await axios
      .post(`${API_URL}contract/create`, data, config);
    return response.data;
  }

  async update(data, Id) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'application/json';
    const response = await axios
      .post(`${API_URL}contract/update/${Id}`, data, config);
    return response.data;
  }

  async destroy(ID) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}contract/delete/${ID}`, config);
    return response.data;
  }

  async getContractData(contractId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}contract/show/${contractId}`, config);
    return response.data;
  }

  async import(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios
      .post(`${API_URL}contract/import`, data, config);

    return response.data;
  }
}

export default new ContractService();