<template>
  <vue3-chart-js
    v-show="earningChart.data?.datasets?.length"
    ref="earningChartRef"
    v-bind="{ ...earningChart }"
    :id="`earning-chart`"
  />
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref, reactive } from "vue";
import dataLabels from "chartjs-plugin-datalabels";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
  },
  setup(props) {
    const earningChartRef = ref(null);

    const earningChart = reactive({
      type: "line",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: { mode: null },
        plugins: {
          datalabels: {
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            color: "#fff",
            font: {
              weight: "bold",
            },
            formatter: function(value) {
              return `${value.y}`;
            },
            align: 'end',
            anchor: 'end',
            padding: 6,
            offset: 15,
          },
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: false,
            text: "Earning Statistics",
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              label: (tooltipItems) => {
                return `Earned: $${tooltipItems.raw.y}`;
              },
            }
          }
        },
        layout: {
          padding: {
            top: 40
          }
        },
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return `$${value}`;
              },
              stepSize: 1000,
            },
            min: 0,
          },
        },
      },
      data: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            tension: 0,
            fill: false,
            borderColor: "#6fda45",
            backgroundColor: "#6fda45",
            borderWidth: 10,
            pointStyle: 'circle',
            pointBorderWidth: 15,
            pointBorderColor: "#6fda45",
            pointHoverBorderWidth: 15,
            hitRadius: 15,
          },
        ],
      },
      plugins: [dataLabels],
    });

    const getData = async () => {
      earningChart.data.labels = props.labels;
      earningChart.data.datasets[0].data = props.datasets;
      earningChartRef.value.update(250)
    };
    return {
      earningChart,
      earningChartRef,
      getData,
    };
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
