<template>
  <!-- side popup modal -->
    <div class="form-field-group">
      <h3 class="form-title">
        <template v-if="contract.Id">View Contract</template>
      </h3>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="contract-id">Contract ID</label>
          <div class="form-field">
            <input
              class="form-control"
              id="contract-id"
              placeholder="Contract ID"
              type="text"
              :value="contract.ContractId"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="offer-id">Offer ID</label>
          <div class="form-field">
            <input
              class="form-control"
              id="offer-id"
              placeholder="Offer ID"
              type="text"
              :value="contract.OfferId"
              disabled
              readonly
            />
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="company-name">Company Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="company-name"
              placeholder="Company Name"
              type="text"
              :value="contract.CompanyName"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="team-name">Team Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="team-name"
              placeholder="Team Name"
              type="text"
              :value="contract.TeamName"
              disabled
              readonly
            />
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="agency-name">Agency Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="agency-name"
              placeholder="Agency Name"
              type="text"
              :value="contract.AgencyName"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="title">Title</label>
          <div class="form-field">
            <input
              class="form-control"
              id="title"
              placeholder="Title"
              type="text"
              :value="contract.Title"
              disabled
              readonly
            />
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="start-date">Start Date</label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                id="start-date"
                :maxDate="new Date()"
                :enableTimePicker="false"
                autoApply
                placeholder="Start Date"
                v-model="contract.StartDate"
                :format="format"
                disabled
                readonly
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
            </div>
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="end-date">End Date</label>
          <div class="form-field">
            <div class="date-field">
              <datepicker
                id="end-date"
                :enableTimePicker="false"
                autoApply
                placeholder="End Date"
                v-model="contract.EndDate"
                :format="format"
                disabled
                readonly
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center radio-fields">
          <label class="form-label" for="status">Status</label>
          <div class="radio-options">
            <ul class="d-flex align-items-center inline-radio">
              <li v-if="contract.Status === 'Active'">
                <input
                  type="radio"
                  id="active"
                  name="radio-group"
                  value="Active"
                  v-model.trim="contract.Status"
                  disabled
                  readonly
                />
                <label for="active">Active</label>
              </li>
              <li v-if="contract.Status === 'Ended'">
                <input
                  type="radio"
                  id="ended"
                  name="radio-group"
                  value="Ended"
                  v-model.trim="contract.Status"
                  disabled
                  readonly
                />
                <label for="ended">Ended</label>
              </li>
              <li v-if="contract.Status === 'Suspended'">
                <input
                  type="radio"
                  id="suspended"
                  name="radio-group"
                  value="Suspended"
                  v-model.trim="contract.Status"
                  disabled
                  readonly
                />
                <label for="suspended">Suspended</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Contract-type">Contract type</label>
          <div class="form-field">
            <input class="form-control" :value="contract.ContractType" disabled readonly/>
          </div>
        </div>
        <div
          class="col form-group d-flex align-items-center"
          v-if="contract.ContractType === 'Hourly'"
        >
          <label class="form-label" for="hourly-rate">Hourly Rate</label>
          <div class="form-field">
            <div class="hourly-rate-field">
              <span class="field-icon">$</span>
              <input
                class="form-control"
                placeholder="Hourly Rate"
                type="text"
                :value="contract.HourlyRate"
                disabled
                readonly
              />
            </div>
          </div>
        </div>
        <div
          class="col form-group d-flex align-items-center"
          v-if="contract.ContractType === 'Fixed Price'"
        >
          <label class="form-label" for="fixed-rate">Fixed Amount</label>
          <div class="form-field">
            <div class="hourly-rate-field">
              <span class="field-icon">$</span>
              <input
                class="form-control"
                placeholder="Fixed Rate"
                type="text"
                :value="contract.FixedPriceAmountAgreed"
                disabled
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-field-group milestones"
        v-if="contract.ContractType === 'Fixed Price' && contract.Milestones && contract.Milestones.length > 0"
      >
        <div
          v-for="(v, index) in contract.Milestones"
          :key="index"
          class="multi-companies step-moreLang"
        >
          <h3 class="form-title">Milestones {{ index + 1 }}</h3>
          <div class="row grid-1">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label" :for="`name-${index}`">Name</label>
              <div class="form-field">
                <input
                  class="form-control"
                  type="text"
                  :id="`name-${index}`"
                  :value="v.Name"
                  disabled
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="row grid-2">
            <div class="col form-group d-flex align-items-center">
              <label class="form-label">Due Date</label>
              <div class="form-field">
                <div class="date-field">
                  <datepicker
                    :id="`due-date-${index + 1}`"
                    v-model="v.DueDate"
                    :minDate="new Date()"
                    :enableTimePicker="false"
                    autoApply
                    placeholder="Due Date"
                    :format="format"
                    disabled
                    readonly
                  >
                    <template #input-icon>
                      <span class="lnr lnr-calendar-full"></span>
                    </template>
                  </datepicker>
                </div>
              </div>
            </div>
            <div class="col form-group d-flex align-items-center">
              <label class="form-label" for="fixed-rate">Amount</label>
              <div class="form-field">
                <div class="hourly-rate-field">
                  <span class="field-icon">$</span>
                  <input
                    class="form-control"
                    placeholder="Amount"
                    type="text"
                    :value="v.Amount"
                    onpaste="return false;"
                    v-on:keypress="HourlyRate($event)"
                    disabled
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center" v-if="false">
          <label class="form-label" for="weekly-salary">Weekly Salary</label>
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Weekly Salary"
              type="text"
              :value="contract.WeeklySalary"
              onpaste="return false;"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="upfront-payment"
            >Upwork Commission (%)</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Upwork Commission (%)"
              type="text"
              :value="contract.UpworkCommission"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="weekly-limit">Weekly Limit</label>
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Weekly Limit"
              type="text"
              :value="contract.WeeklyLimit"
              disabled
              readonly
            />
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Contact-person">Client name</label>
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Client name"
              type="text"
              :value="contract.ContactPerson"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="upfront-payment"
            >Upfront Payment (%)</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Upfront Payment (%)"
              type="text"
              :value="contract.UpfrontPayment"
              disabled
              readonly
            />
          </div>
        </div>
      </div>
      <div class="row grid-2">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Milestone-Status"
            >Milestone Status</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Milestone Status"
              type="text"
              :value="contract.MilestoneStatus"
              disabled
              readonly
            />
          </div>
        </div>
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="Escrow-Refund-Status"
            >Escrow Refund Status</label
          >
          <div class="form-field">
            <input
              class="form-control"
              placeholder="Escrow Refund Status"
              type="text"
              :value="contract.EscrowRefundStatus"
              disabled
              readonly
            />
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import common from "../mixins/common";

export default {
  setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${(day < 10)? '0'+day : day}/${ (month < 10)? '0'+month : month }/${year}`;
    };

    return {
      format,
    };
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  mixins: [common],
  components: {
    Datepicker,
  },
  data: () => ({
    submitted: false,
    contract: {
      Id: 0,
      ContractId: "",
      OfferId: "",
      CompanyName: "",
      TeamName: "",
      AgencyName: "",
      Title: "",
      StartDate: new Date(),
      EndDate: "",
      Status: "Active",
      ContractType: "Hourly",
      HourlyRate: 0,
      FixedPriceAmountAgreed: 0,
      UpfrontPayment: 0,
      WeeklySalary: 0,
      WeeklyLimit: 0,
      ContactPerson: "",
      MilestoneStatus: "",
      EscrowRefundStatus: "",
      UprosterProfileId: "",
    },
  }),
  created() {
    if (
      "contractData" in this.profile &&
      "Id" in this.profile.contractData &&
      this.profile.contractData.Id
    ) {
      this.contract = this.profile.contractData;
    }
  }
};
</script>
