<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">Add Portfolio</h3>

      <div class="col form-group d-flex align-items-center">
        <label class="form-label" for="Contract-type"
          >Select Month<span>*</span></label
        >
        <div class="form-field">
          <select
            class="form-control select-box"
            v-model.trim="contract.selectedMonth"
            @change="getContractsBasedOnIdAndMonth()"
          >
            <option value="">Choose Here</option>
            <option
              v-for="monthOption in monthOptions"
              :value="monthOption.value"
              :key="monthOption.value"
            >
              {{ monthOption.text }}
            </option>
          </select>
          <span
            v-if="submitted && v$.contract.selectedMonth.required.$invalid"
            class="error"
            >* required.</span
          >
        </div>
      </div>
      <div
        v-show="contract.selectedMonth != ''"
        class="col form-group d-flex align-items-center"
      >
        <label class="form-label" for="hourly-rate"
          >Select Contract<span>*</span></label
        >
        <div class="form-field">
          <select
            class="form-control select-box"
            v-model.trim="contract.selectedContract"
          >
            <option value="">Choose Here</option>
            <option
              v-for="contractOption in contractOptions"
              :value="contractOption.value"
              :key="contractOption.value"
            >
              {{ contractOption.text }}
            </option>
          </select>
          <span
            v-if="submitted && v$.contract.selectedContract.required.$invalid"
            class="error"
            >* required.</span
          >
        </div>
      </div>
    </div>
    <div class="form-controls">
      <div class="button-row d-flex justify-content-start">
        <!-- <button
          class="ml-auto secondary-btn"
          title="Cancel"
          type="button"
          @click.prevent="$emit('close')"
        >
          CANCEL
        </button> -->
        <button
          class="ml-3 js-btn-next primary-btn"
          type="submit"
          @click.prevent="submitHandler()"
        >
          Add
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import '@vuepic/vue-datepicker/dist/main.css'
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import common from "../mixins/common";
import NumbersOnly from "../mixins/numbersOnly";
import profileRosterService from "../services/profileRoster.service.js";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  mixins: [NumbersOnly, common],

  data: () => ({
    loading: false,
    submitted: false,
    contract: {
      selectedMonth: "",
      selectedContract: "",
    },
    monthOptions: [],
    contractOptions: [],
  }),

  validations() {
    return {
      contract: {
        selectedMonth: {
          required,
        },

        selectedContract: {
          required,
        },
      },
    };
  },
  async mounted() {
    await this.getContracts();
    this.loading = false;
  },
  methods: {
    async getContracts() {
      let vm = this;
      try {
        vm.loading = true;

        // vm.$store.commit("loadingStatus", true);

        const response = await profileRosterService.getContractEndMonthList({
          UprosterProfileId: this.profile.Id,
        });
        vm.monthOptions = response.data;

        // vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");

        // vm.$store.commit("loadingStatus", false);
      }
    },

    async getContractsBasedOnIdAndMonth() {
      let vm = this;
      try {
        vm.loading = true;

        // vm.$store.commit("loadingStatus", true);

        const response =
          await profileRosterService.getContractBasedOnProfileIdAndMonth({
            UprosterProfileId: vm.profile.Id,
            enddate: this.contract.selectedMonth,
          });
        vm.contractOptions = response.data;

        // vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");

        // vm.$store.commit("loadingStatus", false);
      }
    },

    async submitHandler() {
      let vm = this;
      vm.submitted = true;
      vm.v$.$touch();
      const isValidate = await vm.v$.$validate();
      if (!isValidate) return;
      try {
        const response = await profileRosterService.AddRemovePortfolio({
          contract_id: vm.contract.selectedContract,
          type: "add",
        });
        vm.toastMessage(response.message, "success");
        vm.$emit("refreshContracts");
        vm.$emit("close");

        // vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");

        // vm.$store.commit("loadingStatus", false);
      }
    },
  },
};
</script>
